<template>
  <div class="w-100">
    <div v-if="!loading">
      <table v-if="carts.length" class="table table-hover mb-0">
        <thead>
          <tr>
            <th>Supplier</th>
            <th>Quantity</th>
            <th>Price</th>
            <th>Created at</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(c) in carts" :key="c.id">
            <td>{{ c.supplier ? c.supplier.name : clinicName}}</td>
            <td>{{ c.quantity }}</td>
            <td>
              {{ getCurrencySymbol(c) }}{{ parseFloat(c.total).toFixed(2) }}
            </td>
            <td>{{ c.created_at | formatDate }}</td>
            <td>
              <button
                @click="restoreCart(c)"
                class="btn btn-light btn-sm me-2"
                v-tooltip:bottom="'Continue with cart'"
              >
                <i class="far fa-edit"></i>
              </button>

              <button
                @click="removeCart(c)"
                class="btn btn-light btn-sm"
                v-tooltip:bottom="'Remove cart instance'"
              >
                <i class="far fa-trash text-danger"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-if="!carts.length" class="my-4">
        No carts found
      </div>
    </div>
    <loading v-if="loading" class="my-4"></loading>
  </div>
</template>

<script>
  import Loading from "@/views/components/loader/Loading";

  export default {
    data() {
      return {
        carts: [],
        clientId: this.$route.params.id,
        clinicName: this.$store.getters['auth/user'].clinic.name,
        loading: true
      };
    },
    methods: {
      fetchClientCarts() {
        this.loading = true;

        this.$axios.get(
          process.env.VUE_APP_API_URL + "/cart/client/" + this.clientId
        ).then(({ data }) => {
          this.carts = data;
          this.loading = false;
        });
      },
      getCurrencySymbol(cart) {
        return cart.cart_items[0] && cart.cart_items[0].currency
          ? cart.cart_items[0].currency.symbol : this.$stash.currencies[0].symbol;
      },
      removeCart(cart) {
        this.$axios.delete(
          process.env.VUE_APP_API_URL + "/cart/" + cart.id
        ).then(({ data }) => {
          this.carts.splice(this.carts.indexOf(cart), 1);

          this.$EventBus.$emit("alert", data);
        });
      },
      restoreCart(cart) {
        this.$router.push('/clients/' + this.clientId + '/create-custom-order/' + cart.id);
      },
    },
    created() {
      this.fetchClientCarts();
    },
    filters: {
      formatDate(date) {
        return moment(date).format("L");
      },
    },
    components: {
      Loading
    }
  }
</script>

<style>
</style>