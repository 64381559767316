<template>
  <div class="orders-tab">
    <div class="row mb-0">
      <div class="col-auto my-auto">
        <h5 class="mb-0 client-tab-heading">Orders</h5>
      </div>

      <div class="col text-end">
        <button
          class="btn btn-outline-primary btn-sm"
          @click="createCustomOrder"
        >
          <i class="far fa-user-md me-2"></i>
          Create Custom Order
        </button>
      </div>
    </div>

    <hr class="my-3" />

    <div class="row mb-2">
      <div class="col">
        <ul class="nav nav-tabs">
          <li @click="switchToTab(0)" class="nav-item me-2">
            <a
              href="#orders-tab"
              :class="viewTab == 0 ? 'nav-link active' : 'nav-link'"
              >Orders</a
            >
          </li>
          <li @click="switchToTab(1)" class="nav-item me-2">
            <a
              href="#orders-tab"
              :class="viewTab == 1 ? 'nav-link active' : 'nav-link'"
              >Open Carts</a
            >
          </li>
        </ul>
      </div>
    </div>

    <section v-if="viewTab == 0" class="p-2 mb-4">
      <div class="row mb-2">
        <div class="col text-end">
          <div class="dropleft">
            <button
              class="btn btn-outline-dark btn-sm dropdown-toggle"
              type="button"
              id="sortByDropDown"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Sort by
            </button>
            <div class="dropdown-menu" aria-labelledby="sortByDropDown">
              <a class="dropdown-item" @click="sortOrdersBy('oldest-newest')"
                >Oldest to Newest</a
              >
              <a class="dropdown-item" @click="sortOrdersBy('newset-oldest')"
                >Newest to Oldest</a
              >
              <a class="dropdown-item" @click="sortOrdersBy('total-low-high')"
                >Total - Lowest to Highest</a
              >
              <a class="dropdown-item" @click="sortOrdersBy('total-high-low')"
                >Total - Highest to Lowest</a
              >
            </div>
          </div>
        </div>
      </div>

      <div class="row mb-4" v-if="orders.length > 0">
        <div class="col">
          <table class="table table-hover">
            <thead>
              <tr>
                <th scope="col">ID</th>
                <th scope="col">Title</th>
                <th scope="col">Type</th>
                <th scope="col">Total</th>
                <th scope="col">Status</th>
                <th scope="col">Created</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="o in orders"
                :key="o.id"
                class="cursor-pointer"
                @click="openOrder(o.id)"
              >
                <td>#{{ o.id }}</td>
                <td>{{ o.title }}</td>
                <td class="text-capitalize">{{ o.type }}</td>
                <td>{{ o.symbol }}{{ calculateOrderTotal(o) }}</td>
                <td>
                  <span class="text-capitalize">{{
                    o.status | formatStatus
                  }}</span>
                </td>
                <td>{{ o.created_at | formatDate }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="row" v-else-if="!loading">
        <div class="col">
          <p class="alert alert-primary mb-4">
            <i class="far fa-info me-2"></i>
              This client does not have any orders. To shop with Swandoola
              suppliers or create an order with your own products
              <a :href="'/clients/' + client.id + '/create-custom-order'"
                >click here</a>.
          </p>
        </div>
      </div>

      <loading v-if="loading" class="my-4"></loading>
    </section>

    <section v-if="viewTab == 1" class="p-2 mb-4">
      <cart-list></cart-list>
    </section>

    <!--
      @todo Create better impl.

      <order-items :client="client"></order-items>
    -->
  </div>
</template>

<script>
import CartList from "./order-partials/CartList";
import Loading from "@/views/components/loader/Loading";
import OrderItems from "./order-partials/OrderItems";

// @todo Paginate?

export default {
  data() {
    return {
      client: null,
      orders: [],
      carts: [],
      loading: true,
      viewTab: 0
    };
  },
  methods: {
    calculateOrderTotal(order) {
      if (!order.total) {
        return 'N/A';
      }

      let total = order.total;

      if (order.discount_percent) {
        total = order.total - ((order.total/100) * order.discount_percent);
      }

      return total.toFixed(2);
    },
    createCustomOrder() {
      this.$router.push('/clients/' + this.client.id + '/create-custom-order');
    },
    sortCartInstancesBy(sort) {
      if (sort == "oldest-newest") {
        this.carts.sort(
          (a, b) => new Date(a.created_at) - new Date(b.created_at)
        );
      } else if (sort == "newset-oldest") {
        this.carts.sort(
          (b, a) => new Date(a.created_at) - new Date(b.created_at)
        );
      } else if (sort == "total-low-high") {
        this.carts.sort((a, b) => a.total - b.total);
      } else if (sort == "total-high-low") {
        this.carts.sort((b, a) => a.total - b.total);
      }
    },
    sortOrdersBy(sort) {
      if (sort == "oldest-newest") {
        this.orders.sort(
          (a, b) => new Date(a.created_at) - new Date(b.created_at)
        );
      } else if (sort == "newset-oldest") {
        this.orders.sort(
          (b, a) => new Date(a.created_at) - new Date(b.created_at)
        );
      } else if (sort == "total-low-high") {
        this.orders.sort((a, b) => a.total - b.total);
      } else if (sort == "total-high-low") {
        this.orders.sort((b, a) => a.total - b.total);
      }
    },
    openOrder(orderId) {
      this.$router.push("/orders/" + orderId);
      this.$stash.clientInView = null;
    },
    fetchClientOrders() {
      this.loading = true;

      this.$axios
        .get(
          process.env.VUE_APP_API_URL +
            "/clients/api/" +
            this.client.id +
            "/orders"
        )
        .then(({ data }) => {
          this.orders = data;
          this.loading = false;
        });
    },
    switchToTab(index) {
      this.viewTab = index;
    },
    async fetchClientData() {
      const {
        data: { client },
      } = await this.$axios.get(
        process.env.VUE_APP_API_URL + "/clients/api/" + this.$route.params.id
      );
      this.client = client;
    },
  },
  async mounted() {
    this.$stash.clientInView = this.$route.params.id;
    await this.fetchClientData();
    this.fetchClientOrders();
  },
  filters: {
    formatDate(date) {
      return moment(date).format("L");
    },
    formatDateTime(date) {
      return moment.utc(date).local().format("lll");
    },
    formatStatus(status) {
      return status.replace(/_/g, " ");
    },
  },
  components: {
    CartList,
    Loading,
    OrderItems
  },
};
</script>

<style>
</style>
