<template>
  <div>
    <div class="row">
      <div class="col-4">
        <div class="card border-0 shadow-sm bg-light">
          <div class="card-header bg-primary text-white border-0">
            Supplements
          </div>
          <div class="card-body">
            <ul class="list-unstyled">
              <li class v-for="s in supplements">{{ s.title }}</li>
            </ul>
          </div>
        </div>
      </div>

      <!--  -->

      <div class="col-4">
        <div class="card border-0 shadow-sm bg-light">
          <div class="card-header bg-primary text-white border-0">
            Functional Tests
          </div>
          <div class="card-body">
            <ul class="list-unstyled">
              <li class v-for="t in tests">{{ t.title }}</li>
            </ul>
          </div>
        </div>
      </div>

      <!--  -->

      <div class="col-4">
        <div class="card border-0 shadow-sm bg-light">
          <div class="card-header bg-primary text-white border-0">
            Custom Orders
          </div>
          <div class="card-body">
            <ul class="list-unstyled">
              <li class v-for="c in customs">{{ c.title }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["client"],
  data() {
    return {
      supplements: [],
      tests: [],
      customs: [],
    };
  },
  methods: {
    fetchOrderItems() {
      this.$axios
        .get(
          process.env.VUE_APP_API_URL +
            "/clients/api/" +
            this.client.id +
            "/order-items"
        )
        .then(({ data }) => {
          this.supplements = data.supplements;
          this.tests = data.tests;
          this.customs = data.customs;
        });
    },
  },
  mounted() {
    this.fetchOrderItems();
  },
};
</script>

<style>
</style>